<template>
   <div class="muro-statistics">
      <div class="muro-statistics-top">
         <SectionTitle
            :title="title_component.title"
            :subtitle="title_component.subtitle"
         />
         <div class="muro-statistics-top-filter">
            <!-- Select mes -->
            <div
               class="select-container month"
               :class="open_select_list == 'month' && 'active'"
               @click="openSelect('month')"
            >
               <div class="select-btn">
                  <div class="item">
                     {{ month_selected.slice(0, 3) }}
                  </div>
                  <div class="icon">
                     <font-awesome-icon
                        :icon="
                           open_select_list == 'month'
                              ? 'angle-up'
                              : 'angle-down'
                        "
                     >
                     </font-awesome-icon>
                  </div>
               </div>
               <div class="select-list" v-if="open_select_list == 'month'">
                  <div
                     v-for="(month, k) in month_list"
                     :key="k"
                     class="list-item"
                     @click="selectMonth(month)"
                  >
                     {{ month.slice(0, 3) }}
                  </div>
               </div>
            </div>
            <!-- Select año -->
            <div
               class="select-container year"
               :class="open_select_list == 'year' && 'active'"
            >
               <div class="select-btn" @click="openSelect('year')">
                  <div class="item">
                     {{ year_selected }}
                  </div>
                  <div class="icon">
                     <font-awesome-icon
                        :icon="
                           open_select_list == 'year'
                              ? 'angle-up'
                              : 'angle-down'
                        "
                     >
                     </font-awesome-icon>
                  </div>
               </div>
               <div class="select-list" v-if="open_select_list == 'year'">
                  <div
                     v-for="(year, k) in year_list"
                     :key="k"
                     class="list-item"
                     @click="selectYear(year)"
                  >
                     {{ year }}
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="muro-statistics-container"></div>
   </div>
</template>

<script>
import SectionTitle from "../Section/SectionTitle.vue";

export default {
   components: { SectionTitle },
   data() {
      return {
         title_component: {
            title: "Posts",
            subtitle: "Estadísticas generales de la actividad del muro",
         },
         open_select_list: "",
         year_list: [2021, 2022],
         year_selected: 2021,
         month_list: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
         ],
         month_selected: "Enero",
      };
   },
   methods: {
      openSelect(item) {
         if (item == this.open_select_list) {
            this.open_select_list = "";
         } else {
            this.open_select_list = item;
         }
      },
      selectYear(year) {
         this.year_selected = year;
         setTimeout(() => {
            this.open_select_list = "";
         }, 200);
      },
      selectMonth(month) {
         this.month_selected = month;
         setTimeout(() => {
            this.open_select_list = "";
         }, 200);
      },
   },
};
</script>